import { Spinner } from '@ufx-ui/core'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _reverse from 'lodash/reverse'
import _toLower from 'lodash/toLower'
import React, { useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import SearchBar from '../core/SearchBar'
import Episode from './Episode'

const SUGGESTIONS_COUNT = 6

function Episodes({
  loading,
  podcasts,
  heading = 'All Episodes',
  showSearch = false,
  showLoader = false,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  // Limit suggested episodes to 4
  podcasts = podcasts.slice(
    0,
    heading === 'All Episodes' ? Infinity : SUGGESTIONS_COUNT
  )
  // show latest first
  const reversed = _reverse([...podcasts])
  const filtered = _filter(
    reversed,
    ({ title, guest }) =>
      _includes(_toLower(title), _toLower(searchTerm)) ||
      _includes(_toLower(guest), _toLower(searchTerm))
  )

  return (
    <div className='episodes'>
      <div className='heading'>{heading}</div>
      <div className='divider' />
      {showSearch && (
        <>
          <br />
          <Row>
            <Col sm={12} md={6}>
              <SearchBar
                placeholder='Search Episode here'
                value={searchTerm}
                onChange={setSearchTerm}
              />
            </Col>
          </Row>
          <br />
        </>
      )}

      <Row>
        {showLoader && _isEmpty(podcasts) ? (
          <Col xs={12}>
            <Spinner />
          </Col>
        ) : (
          _map(filtered, (podcast, index) => (
            <Col md={6} key={index}>
              <Episode loading={loading} podcast={podcast} />
            </Col>
          ))
        )}
      </Row>
    </div>
  )
}

export default Episodes
