import React from 'react'

import { apiURL } from '../../config'
import { isDev } from '../../../env'
import Placeholder from '../../images/placeholder.png'

const showPlaceholderImage = isDev()

function EpisodeImage(props) {
  const { avatar, episodeNum, title, showTitle = true } = props

  return (
    <div className='episode-image'>
      {showPlaceholderImage ? (
        <img src={Placeholder} alt='avatar' />
      ) : (
        <img src={apiURL(avatar)} alt='avatar' />
      )}
      {showTitle && (
        <div className='title'>
          <div className='episode-no'>{episodeNum}</div>
          {title && <div className='episode-guest'>{title}</div>}
        </div>
      )}
    </div>
  )
}

export default EpisodeImage
