import { Input } from '@ufx-ui/core'
import cx from 'classnames'
import React from 'react'

function InputWrapper(props) {
  const { className, ...rest } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Input className={cx('biz-input', className)} {...rest} />
  )
}

export default InputWrapper
