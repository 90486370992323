import cx from 'classnames'
import React from 'react'

export default function Skeleton({ variant = 'rect', width = 5, height = 5 }) {
  const widthStyle = typeof width === 'string' ? width : `${width}rem`
  const heightStyle = typeof height === 'string' ? height : `${height}rem`
  return (
    <div
      style={{
        width: widthStyle,
        height: heightStyle,
        marginRight: '2rem',
      }}
      className={cx('skeleton', variant)}
    />
  )
}
