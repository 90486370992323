import React from 'react'

import Skeleton from './Skeleton'

export default function EpisodeSkeleton() {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Skeleton width='40%' height={25} />
        <Skeleton width='60%' height={25} />
      </div>
    </div>
  )
}
