import { faLock, faUnlock, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import { Button } from '@ufx-ui/core'
import cx from 'classnames'
import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { paramCase } from 'change-case'

import useSize from '../../hooks/useSize'
import { getEpisodeNum, getEpisodePercent } from '../../utils'
import EpisodeImage from '../EpisodeImage'
import EpisodeSkeleton from '../Skeleton/EpisodeSkeleton'

const EPISODE_MOBILE_BP = 450

function Episode({ loading, podcast }) {
  const { guest, title = '', episodeNumber, avatar } = podcast
  const percent = getEpisodePercent(podcast)
  const [episodeNum, episodeNumStr] = getEpisodeNum(episodeNumber)
  const [ref, { width }] = useSize()
  const isMobile = width < EPISODE_MOBILE_BP

  const handleEpisodeClick = () => {
    navigate(`/episodes/${episodeNumber}/${paramCase(title)}`)
  }

  if (loading) {
    return <EpisodeSkeleton />
  }

  return (
    <>
      <div
        className={cx('episode-no', {
          hidden: !isMobile,
          visible: isMobile,
        })}
      >
        {episodeNumStr}
      </div>
      <div
        className='episode-link'
        onClick={handleEpisodeClick}
        onKeyPress={handleEpisodeClick}
        tabIndex='0'
        role='button'
        ref={ref}
      >
        <div className={cx('episode', { mobile: isMobile })}>
          <Row>
            {!isMobile && (
              <Col sm={5} md={4} className='img-wrapper hidden-xs'>
                <EpisodeImage avatar={avatar} episodeNum={episodeNumStr} />
              </Col>
            )}
            <Col
              className={cx('content', { 'full-width': isMobile })}
              sm={7}
              md={8}
            >
              <div>
                <div className='heading-3'>{title}</div>
                <p className='guest-name'>with {guest}</p>
              </div>

              <Row className='actions'>
                <Col xs={12}>
                  <div
                    className={cx('ufx-button ufx-button--primary unlocked', {
                      full: percent >= 100,
                    })}
                  >
                    <FontAwesomeIcon icon={percent === 0 ? faLock : faUnlock} />
                    <span>
                      {percent.toFixed(1)}% {!isMobile && 'Unlocked'}
                    </span>
                  </div>
                  <Button
                    intent='secondary'
                    className='play-btn'
                    onClick={handleEpisodeClick}
                  >
                    <FontAwesomeIcon icon={faPlay} />
                    <span>Listen</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Episode
