export const BP_XS = 320
export const BP_SM = 575
export const BP_MD = 768
export const BP_LG = 992

export const getBreakpoints = width => {
  const result = {
    isXS: false,
    isSM: false,
    isMD: false,
    isLG: false,
  }
  if (width <= BP_XS) {
    result.isXS = true
    result.isSM = true
    result.isMD = true
    result.isLG = true
  } else if (width <= BP_SM) {
    result.isSM = true
    result.isMD = true
    result.isLG = true
  } else if (width <= BP_MD) {
    result.isMD = true
    result.isLG = true
  } else if (width <= BP_LG) {
    result.isLG = true
  }

  return result
}
