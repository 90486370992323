import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Input from '../Input'

function SearchBar(props) {
  return (
    <Input
      className='search-bar'
      rightElement={
        <div className='search-icon'>
          <FontAwesomeIcon icon={faSearch} />
        </div>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default SearchBar
